export default {
  path: '/system',
  redirect: '/system/organization',
  meta: {
    id: 'a3255836-688c-401b-a0aa-3c4a93c1f3a4',
    name: 'systemSetting',
    title: '设置',
    level: 1,
    icon: 'fa fa-rss-square'
  },
  children: [
    {
      path: 'organization',
      meta: {
        id: '06DFF8BD-75D1-4945-B1C2-9C3F66DD4F94',
        title: '组织架构',
        name: 'organizationManage',
        level: 2,
        apis: [
          'get:/paas-ability-os/v1/partner/{partnerId}/organs',
          'get:/paas-ability-os/v1/partner/{partnerId}/departments',
          'get:/paas-ability-os/v1/partner/{partnerId}/stations'
        ],
        actions: [
          {
            title: '新增组织',
            id: '8085C3C6-E903-4069-B4B6-AF913E23034D',
            name: 'addOrganization',
            apis: [
              'post:/paas-ability-os/v1/partner/{partnerId}/department',
              'get:/paas-ability-os/v1/partner/{partnerId}/department/verify-name'
            ]
          },
          {
            title: '编辑组织',
            id: '5BDBB30C-8E7F-4267-9002-5D47BE3925A7',
            name: 'editOrganization',
            apis: [
              'put:/paas-ability-os/v1/partner/{partnerId}/department/{departmentId}',
              'get:/paas-ability-os/v1/partner/{partnerId}/department/verify-name'
            ]
          }, {
            title: '删除组织',
            id: 'F1403F9D-5955-4AB7-9057-0A61E2B669F2',
            name: 'deleteOrganization',
            apis: [
              'delete:/paas-ability-os/v1/partner/{partnerId}/department/{departmentId}',
              'get:/paas-ability-os/v1/partner/{partnerId}/department/{departmentId}/check-lower'
            ]
          }, {
            title: '新增岗位',
            id: 'B2D79DDE-F3DB-4D49-8F34-CC8E83B9BD7C',
            name: 'addStation',
            apis: [
              'post:/paas-ability-os/v1/partner/{partnerId}/station',
              'get:/paas-ability-os/v1/partner/{partnerId}/station/verify-name'
            ]
          }, {
            title: '编辑岗位',
            id: 'FA431DAA-5E7F-451D-9DAE-20C8EB46BBB8',
            name: 'editStation',
            apis: [
              'get:/paas-ability-os/v1/partner/{partnerId}/stations',
              'put:/paas-ability-os/v1/partner/{partnerId}/station/{stationId}',
              'get:/paas-ability-os/v1/partner/{partnerId}/station/verify-name',
              'get:/paas-ability-os/v1/partner/{partnerId}/station/{stationId}'
            ]
          }, {
            title: '删除岗位',
            id: '492BAC49-6058-4436-8135-17AD9B83D8CA',
            name: 'deleteStation',
            apis: [
              'delete:/paas-ability-os/v1/partner/{partnerId}/station/{stationId}',
              'get:/paas-ability-os/v1/partner/{partnerId}/station/{stationId}/check-lower'
            ]
          }
        ]
      },
      component: () => import('@/web-app/system-setting/organization/src/index.vue')
    },
    {
      path: 'role',
      meta: {
        id: 'F1DB5B93-C55A-4669-9DE0-88412C9156D1',
        title: '角色管理',
        name: 'roleManage',
        level: 2,
        apis: [
          'get:/paas-ability-os/v1/partner/{partnerId}/roles'
        ],
        actions: [
          {
            title: '新增角色',
            id: 'd8b95da2-7f63-413e-b65d-8d2fc196fb33',
            name: 'add-role',
            apis: [
              'post:/paas-ability-os/v1/partner/{partnerId}/role',
              'get:/paas-ability-os/v1/partner/{partnerId}/operations',
              'get:/paas-ability-os/v1/partner/{partnerId}/organs'
            ]
          },
          {
            title: '编辑角色',
            id: 'a600984a-4843-4b53-9667-0db780836b6d',
            name: 'edit-role',
            apis: [
              'get:/paas-ability-os/v1/partner/{partnerId}/role/{roleId}',
              'put:/paas-ability-os/v1/partner/{partnerId}/role/{roleId}',
              'get:/paas-ability-os/v1/partner/{partnerId}/operations',
              'get:/paas-ability-os/v1/partner/{partnerId}/organs'
            ]
          },
          {
            title: '删除角色',
            id: 'B0C997C4-7A99-47EC-A183-21CB1255AB0E',
            name: 'delete-role',
            apis: [
              'delete:/paas-ability-os/v1/partner/{partnerId}/role/{roleId}'
            ]
          }
        ]
      },
      component: () => import('@/web-app/system-setting/roleManage/src/index.vue')
    },
    {
      path: 'employee',
      meta: {
        id: 'B7AC982E-D7B7-469F-A62A-BB98A176361C',
        title: '员工管理',
        name: 'employeeManage',
        level: 2,
        apis: [
          'get:/paas-ability-os/v1/partner/{partnerId}/employees',
          'get:/paas-ability-os/v1/partner/{partnerId}/organs',
          'get:/paas-ability-os/v1/partner/{partnerId}/departments'
        ],
        actions: [
          {
            title: '员工离职',
            id: '5e34a0be-c9f7-478a-a69d-eef64d8f9f54',
            name: 'delete-employee',
            apis: [
              'put:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}/delete'
            ]
          },
          {
            title: '新增员工',
            id: '1cba5caf-b651-40de-9a58-b12ba23b423b',
            name: 'add-employee',
            apis: [
              'get:/paas-ability-os/v1/partner/{partnerId}/organ-departments',
              'get:/paas-ability-os/v1/partner/{partnerId}/stations',
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/verify-employeeNo',
              'post:/paas-ability-os/v1/partner/{partnerId}/employee',
              'put:/paas-ability-os/v1/partner/{partnerId}/user/banding',
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}/phone',
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/check-account'
            ]
          },
          {
            title: '编辑员工',
            id: '8fc9df99-1aca-404d-ad4e-da9f2a0701d5',
            name: 'edit-employee',
            apis: [
              'get:/paas-ability-os/v1/partner/{partnerId}/organ-departments',
              'get:/paas-ability-os/v1/partner/{partnerId}/stations',
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/verify-employeeNo',
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}',
              'put:/paas-ability-os/v1/partner/{partnerId}/user/banding',
              'put:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}',
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}/phone',
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/check-account'
            ]
          },
          {
            title: '分配权限',
            id: '9d3db6d0-9119-4902-9f58-ac00600a16d4',
            name: 'permission-employee',
            apis: [
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}',
              'put:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}/role',
              'get:/paas-ability-os/v1/partner/{partnerId}/organ-roles'
            ]
          },
          {
            title: '员工详情',
            id: '4f5e54c4-f824-4728-b587-83ffd53b70a2',
            name: 'detail-employee',
            apis: [
              'get:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}'
            ]
          }, {
            title: '导入员工',
            id: 'd7332794-e441-455f-8fb7-2c35eb9d63fd',
            name: 'import-employee',
            apis: [
              'post:/paas-ability-os/v1/partner/{partnerId}/improt-employees',
              'get:/paas-ability-os/v1/partner/{partnerId}/grantinfo',
              'get:/paas-ability-os/v1/partner/{partnerId}/work-wx/departments',
              'get:/paas-ability-os/v1/partner/{partnerId}/work-wx/users',
              'get:/paas-ability-os/v1/partner/{partnerId}/work-wx/employs',
              'post:/paas-ability-os/v1/partner/{partnerId}/work-wx/employees',
              'put:/paas-ability-os/v1/partner/{partnerId}/work-wx/synemployees'
            ]
          }, {
            title: '批量操作',
            id: 'f5842362-f525-4d76-8dcf-cea140cf041b',
            name: 'distribute-option',
            apis: [
              'get:/paas-ability-os/v1/partner/{partnerId}/organ-departments',
              'get:/paas-ability-os/v1/partner/{partnerId}/stations',
              'post:/paas-ability-os/v1/partner/{partnerId}/batch-link-station',
              'get:/paas-ability-os/v1/partner/{partnerId}/organ-roles',
              'post:/paas-ability-os/v1/partner/{partnerId}/batch-link-role'
            ]
          }, {
            title: '员工账号申请',
            id: 'a6146dba-cb5b-40d5-963d-88cf33f511a6',
            name: 'empRegist',
            apis: [
              'get:/ability-saas-service-partener/v1/partner/{partnerId}/regist/employs',
              'put:/ability-saas-service-partener/v1/partner/{partnerId}/regist/employ/{empRegId}'
            ]
          }, {
            title: '解绑微信',
            id: 'f3ab120b-8630-4a85-843d-0b69a30df2ac',
            name: 'unbindWx',
            apis: [
              'put:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}/unbound-wx'
            ]
          }]
      },
      component: () => import('@/web-app/system-setting/employee/src/index.vue')
    },
    // {
    //   path: 'personnel-structure',
    //   meta: {
    //     id: 'd6154fee-0249-43d3-8dfb-9c300edc995b',
    //     title: '成员层级',
    //     name: 'personnel-structure',
    //     level: 2,
    //     apis: [
    //       'get:/paas-ability-os/v1/partner/{partnerId}/emplevels'
    //     ]
    //   },
    //   component: () => import('@/web-app/system-setting/personnel-structure/index.vue')
    // }
    {
      path: 'wx-setting',
      meta: {
        id: 'ca8b92d9-bc7a-483f-92d3-651e3bad06a0',
        title: '微信授权',
        name: 'wx-setting',
        level: 2,
        apis: [
          'get:/paas-ability-os/v1/partner/{partnerId}/grantinfo',
          'post:/paas-ability-os/v1/partner/{partnerId}/type/{typeId}/grantinfos',
          'get:/saas-ability-mps/v1/partner/{partnerId}/weixin-auth-config/template',
          'post:/saas-ability-mps/v1/partner/{partnerId}/weixin-auth-config/template',
          'get:/saas-ability-mps/v1/partner/{partnerId}/weixin-auth-config/template/preview',
          'get:/ability-saas-service-partener/v1/upload',
          'delete:/paas-ability-os/v1/partner/{partnerId}/grantinfo',
          'post:/paas-ability-os/v1/partner/{partnerId}/type/{typeId}/upload-file',
          'delete:/saas-ability-mps/v1/partner/{partnerId}/weixin-auth-config/template'
        ]
      },
      component: () => import('@/web-app/system-setting/wx-setting/index.vue')
    },
    {
      path: 'app-setting',
      meta: {
        id: '074dda17-fe48-4a83-b05b-63a188df9d7c',
        title: 'APP配置',
        name: 'appSetting',
        level: 2,
        apis: [
          'get:/paas-ability-os/v1/partner/{partnerId}/grantinfo',
          'post:/paas-ability-os/v1/partner/{partnerId}/type/{typeId}/grantinfos'
        ]
      },
      component: () => import('@/web-app/system-setting/app-setting/index.vue')
    }
    // {
    //   path: 'workwx-setting',
    //   meta: {
    //     id: 'ae2adfb8-67b9-4a80-8285-8c97bc85d544',
    //     title: '企微授权',
    //     name: 'workwx-setting',
    //     level: 2,
    //     apis: [
    //       'get:/paas-ability-os/v1/partner/{partnerId}/grantinfo',
    //       'post:/paas-ability-os/v1/partner/{partnerId}/type/{typeId}/grantinfos',
    //       'delete:/paas-ability-os/v1/partner/{partnerId}/grantinfo',
    //       'get:/paas-ability-os/v1/partner/{partnerId}/type/{typeId}/field/{fieldName}/unique'
    //     ]
    //   },
    //   component: () => import('@/web-app/system-setting/workwx-setting/index.vue')
    // }
  ]
}

