import financeManage from './finance-manage'

import systemSetting from './system-setting'

import userManage from './user-manage'

import userSetting from './user-setting'

export { default as whiteList } from './white-list.js'

export { default as greyList } from './grey-list.js'

export default [financeManage, systemSetting, userManage, userSetting]
