export default {
  path: '/user-setting',
  meta: {
    id: '',
    name: 'userManage',
    icon: 'hnjing-icon hnjing-icon-pingfencaise',
    title: '个人设置',
    partnerType: '0,1,2',
    option: {
      crumbTitle: '首页',
      crumbLink: '/'
    },
    promote: true
  },
  children: [
    {
      path: 'information',
      meta: {
        id: '',
        name: 'userInformation',
        title: '个人资料',
        partnerType: '0,1,2',
        icon: 'mps-icon icon-gerenziliao',
        apis: [
          'get:/paas-ability-os/v1/partner/{partnerId}/employ/{employeeId}'
        ]
      },
      component: () => import('@/web-app/user-setting/index.vue')
    },
    {
      path: 'modify',
      meta: {
        id: '',
        name: 'userModify',
        title: '修改密码',
        partnerType: '0,1,2',
        icon: 'mps-icon icon-xiugaimima',
        apis: [
          'get:/paas-ability-us/v1/user/{userId}/checkPassword',
          'put:/paas-ability-us/v1/user/{userId}/password'
        ]
      },
      component: () => import('@/web-app/user-setting/modify.vue')
    }
  ]
}
