import Vue from 'vue'
import App from './App.vue'
// import router from './router1/index'
import ElementUI from 'element-ui'
import {
  JwFrame,
  JwInput,
  JwNumber,
  JwUpload,
  JwTree,
  JwTreeSelectTransfer,
  JwInputTree,
  JwMobile,
  JwHalfScreenDialog,
  JwImgView,
  JwTable,
  JwForm,
  JwFullScreenDialog
} from '@jw/vue-components-v2'
import axios from 'axios'
import auto from './apis/permission'
import Utils from '@jw/vue-common-library'
import mixin from './apis/mixin'
import './assets/css/common.less'
import './assets/css/reset.css'
import './assets/css/css/font-awesome.css'
import '@jw/vue-components-v2/theme/index.css'
import clipboard from 'v-clipboard'
import ButtonDbClick from './button'
import router from './router'
import { Auth, errorInterceptor } from '@jw/common-library-v2'

ButtonDbClick(axios)
Vue.config.productionTip = false

Vue.prototype.$axios = axios

const domainString = location.hostname.split('.')
const domain = [domainString[domainString.length - 2], domainString[domainString.length - 1]].join('.')
const auth = new Auth(domain, 'boss_token')

Vue.prototype._auth = auth

Vue.use(clipboard)
// Add a request interceptor
axios.interceptors.request.use(function (config) {
  config.headers.token = auth.getToken()
  return config
}, function (error) {
  // Do something with request error
  return Promise.reject(error)
})

// Add a response interceptor
errorInterceptor(axios, { loginUrl: '/#/login', vm: window.global, needProcess: () => true })

if (process.env.NODE_ENV === 'development') {
  // 开发模式下启用 vue-devtools 调试
  Vue.config.devtools = true
}

Vue.use(ElementUI)

Vue.use(JwFrame)
Vue.use(JwTable)
Vue.use(JwForm)
Vue.use(JwFullScreenDialog)
Vue.use(JwInput)
Vue.use(JwTree)
Vue.use(JwTreeSelectTransfer)
Vue.use(JwHalfScreenDialog)
Vue.use(JwNumber)
Vue.use(JwUpload)
Vue.use(JwInputTree)
Vue.use(JwMobile)
Vue.use(JwImgView)

Vue.use(ElementUI)

Vue.use(Utils)
Vue.use(auto)
Vue.use(mixin)

window.global = new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

auth.onUserIdChange = function (userId) {
  if (!userId) {
    auth.clearToken()
    sessionStorage.clear()
    window.location.reload()
  }
}

// 更新权限
const xhr = new XMLHttpRequest()
xhr.open('get', '/api/update_current_permission', false)
xhr.setRequestHeader('token', auth.getToken())
xhr.send()
