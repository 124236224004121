export default {
  path: '/finance-manage',
  meta: {
    id: 'a69f090b-2e8f-41f4-a484-3069bdd1a4ab',
    name: 'financeManage',
    title: '财务管理',
    level: 1,
    icon: '',
    apis: [
      'get:/saas-job/v1/partner/{partnerId}/orders',
      'get:/saas-job/v1/partner/{partnerId}/commissions',
      'get:/saas-job/v1/partner/{partnerId}/commission-orders'
    ]
  },
  component: () => import('@/web-app/finance-manage/index.vue')
}
