export default {
  install (Vue) {
    Vue.mixin({
      methods: {
        listtoTree (arr, id = 'id', pid = 'parentid', child = 'children', root = 0) {
          const tree = []
          const map = {}
          for (let i = 0; i < arr.length; i++) {
            const item = arr[i]
            const key = item[id]
            const pkey = item[pid]
            const obj = {}
            obj[child] = []
            Object.assign(item, obj, map[key])
            if (map[key]) {
              map[key][child].forEach(function (child) {
                child[pid] = item
              })
            }
            map[key] = item
            if (pkey === root) {
              tree.push(item)
            } else {
              item[pid] = map[pkey]
              if (map[pkey]) {
                map[pkey][child].push(item)
              } else {
                const obj = {}
                obj[child] = [item]
                map[pkey] = obj
              }
            }
          }
          for (const key in map) {
            if (!map[key].children.length) {
              delete map[key].children
            }
          }
          return tree
        },
        openWindow (url) {
          const url1 = window.location.pathname + url
          const a = document.createElement('a')
          a.setAttribute('href', url1)
          a.setAttribute('target', '_blank')
          a.click()
        },
        isPrGlobal () {
          return this._auth.userInfo.stationType !== 3
        }
      }
    })
  }
}
