import regExp from './regExp'
import axios from 'axios'
import { Message } from 'element-ui'

const path = {
  apiCrmOS: '/paas-ability-os/v1/',
  apiCrmUs: '/paas-ability-us/v1/',
  apiMsg: '/paas-ability-msg/v1/',
  apiCRM: '/saas_ability_crm/v1/',
  apiCrmPs: '/saas_ability_ps/v1/',
  apiMps: '/saas-ability-mps/v1/',
  apiTAG: '/paas_ability_tag/v1/',
  apiCUMS: '/cums/v1/',
  apiCsexpand: '/csexpand/v1/',
  apiBI: '/biopen/v1/',
  apiPlatform: '/platform/v1/',
  apiOperation: '/ability-saas-service-partener/v1/',
  apiXMF: '/xmf-back/v1/',
  apiJob: '/saas-job/v1'
}

class Server {
  constructor (http = axios, allError = Message.error) {
    this.$http = http
    this.error = function (err, isadd) {
      return function (res) {
        if (err) {
          err(res.response.data)
        }
        if (isadd || !err) {
          const resData = res.response.data
          const msg = resData.errors && resData.errors.map(function (item) {
            return item.message
          }).join(',')
          allError(msg || resData.message || resData.msg || '网络异常，请稍后再试')
        }
      }
    }
  }

  /**
   * @returns {<{
   * departmentId: number,
   * employeeId: number,
   * employeeName: string,
   * employeeNo: string,
   * partnerId: number,
   * partnerName: string,
   * partnerType: number,
   * phone: string,
   * stationId: number,
   * stationType: number,
   * userId: number
   * }>}
  */
  get userInfo () {
    return window.global._auth.userInfo
  }

  // 提供方法清空所有为空的字段
  static clearAllNull (obj) {
    let o
    if (obj instanceof Array) {
      o = []
      obj.forEach(function (item) {
        o.push(Server.clearAllNull(item))
      })
    } else if (obj instanceof Object) {
      o = Object.assign({}, obj)
      for (const key in o) {
        if (!o[key] && o[key] !== 0) {
          delete o[key]
        } else {
          o[key] = Server.clearAllNull(o[key])
        }
      }
    }
    return o || obj
  }

  // 提供方法清空一层为空的obj字段
  static clearNull (obj) {
    const o = Object.assign({}, obj)
    for (const key in o) {
      if (!o[key] && o[key] !== 0) {
        delete o[key]
      }
    }
    return o
  }

  static clearTrim (obj) {
    const o = Object.assign({}, obj)
    for (const key in o) {
      if (!o[key] && o[key] !== 0 && typeof (o[key]) !== 'boolean' && typeof (o[key]) !== 'string') {
        delete o[key]
      } else if (typeof (o[key]) === 'string' || o[key] instanceof String) {
        o[key] = o[key].trim()
      }
    }
    return o
  }

  static $message (self, response) {
    if (!(response && response.headers && response.headers['x-response-by'] && response.headers['x-response-by'] === 'gateway')) {
      Message({
        type: 'error',
        message: (response && response.data && (response.data.msg || response.data.message || (response.data.errors && response.data.errors.map(function (item) {
          return item.message
        }).join(',')))) || '网络异常，请稍后再试'
      })
    } else if (!response.headers) {
      Message(response && response.stack)
    }
  }

  // 验证集合
  static regExp (type) {
    return regExp[type]
  }
}

Server.path = path
export default Server
