let auto = []
let cb = []
let hasRes = false
export default {
  setAuto (root) {
    hasRes = true
    auto = root.map(function (item) {
      return item.opKid
    })
    cb.forEach(function (item) {
      item()
    })
    cb = []
  },
  onRootChange (fuc) {
    if (hasRes) {
      fuc()
    } else {
      cb.push(fuc)
    }
  },
  permission (id) {
    // console.log('auto', auto)
    const result = ~auto.indexOf(id)
    return result
  },
  install: function (Vue) {
    Vue.mixin({
      methods: {
        onRootChange: this.onRootChange,
        permission: this.permission
      }
    })
  }
}
