import Vue from 'vue'
import Router from 'vue-router'

import MainChildren from './router'
import axios from 'axios'
import Api from '@/apis/index'

Vue.use(Router)

const router = new Router({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/components/main.vue'),
      children: MainChildren
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/web-app/login/index.vue')
    },
    {
      path: '/developers-doc',
      name: 'developers-doc',
      meta: {
        auth: 1
      },
      component: {
        render (h) {
          return h('router-view')
        }
      },
      children: [
        {
          path: 'wxwork',
          name: 'developers-wxwork-doc',
          component: () => import('@/components/developers-doc/developers-wxwork-doc.vue')
        },
        {
          path: 'wx',
          name: 'developers-wx-doc',
          component: () => import('@/components/developers-doc/developers-wx-doc.vue')
        }, {
          path: 'wxpay',
          name: 'developers-wxpay-doc',
          component: () => import('@/components/developers-doc/developers-wxpay-doc.vue')
        }, {
          path: 'wxmessage',
          name: 'developers-wxmessage-doc',
          component: () => import('@/components/developers-doc/developers-wxmessage-doc.vue')
        }, {
          path: 'wx-miniprogram',
          name: 'developers-wxminiprogram-doc',
          component: () => import('@/components/developers-doc/developers-wx-program-doc.vue')
        }
      ]
    }
  ]
})

router.beforeEach((to, from, next) => {
  const auth = Vue.prototype._auth
  const userId = auth.getUserId()
  const userInfo = auth.userInfo
  if (!userId && to.path !== '/login') {
    auth.clearToken()
    auth.userInfo = null
    router.push({
      path: '/login',
      query: {
        backUrl: encodeURIComponent(window.location.href)
      }
    })
  } else if (!userInfo && to.path !== '/login') {
    axios.get(`${Api.path.apiCrmOS}user/${userId}`).then(({ data }) => {
      if (!data[0]) {
        router.push({ path: '/login' })
        return
      }
      auth.userInfo = { userId, ...data[0] }
      next()
    }).catch((e) => {
      console.error('err ====>', e)
      router.push({ path: '/login' })
    })
  } else {
    next()
  }
})

export default router
