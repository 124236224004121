export default {
  path: 'user-manage',
  meta: {
    id: '79b1f6c2-d3f2-4eb1-b807-0205ad9feee6',
    name: 'userManage',
    title: '用户管理',
    level: 1,
    icon: '',
    apis: [
      'get:/saas-job/v1/partner/{partnerId}/users'
    ]
  },
  component: () => import('@/web-app/user-manage/index.vue')
}
