/**
 * 数据查询提交按钮不能连续点击
 */

function findButtonElement (ele) {
  if (ele.tagName === 'BUTTON' && ele.getAttribute('dl') === null) {
    return ele
  } else if (ele.parentElement) {
    return findButtonElement(ele.parentElement)
  } else {
    return null
  }
}

export default function (axios) {
// Add a request interceptor
  axios.interceptors.request.use(function (config) {
    if (window && window.event) {
      var btn = findButtonElement(window.event.target)
      if (btn && btn.className && btn.className.indexOf('is-loading') < 0) {
        btn.className = btn.className + ' is-loading'
        setTimeout(() => {
          if (config.targetButton) {
            var loading = document.createElement('i')
            loading.setAttribute('name', 'loading')
            loading.className = 'el-icon-loading'
            btn.insertBefore(loading, btn.children.length > 0 ? btn.children[0] : null)
          }
        }, 1000)
        config.targetButton = btn
      }
    }
    return config
  }, function (error) {
    return Promise.reject(error)
  })

  // Add a response interceptor
  axios.interceptors.response.use(function (response) {
    if (response.config?.targetButton) {
      response.config.targetButton.className = response.config.targetButton.className.replace(' is-loading', '')
      const btn = response.config.targetButton
      setTimeout(() => {
        const loading = document.getElementsByName('loading')
        if (loading && loading.length > 0) {
          btn.removeChild(loading[0])
        }
      }, 0)
      response.config.targetButton = null
      delete response.config.targetButton
    }
    return response
  }, function (error) {
    if (error.config?.targetButton) {
      error.config.targetButton.className = error.config.targetButton.className.replace(' is-loading', '')
      const btn = error.config.targetButton
      setTimeout(() => {
        const loading = document.getElementsByName('loading')
        if (loading && loading.length > 0) {
          btn.removeChild(loading[0])
        }
      }, 0)
      error.config.targetButton = null
      delete error.config.targetButton
    }
    return Promise.reject(error)
  })
}
